<template>
  <template v-if="details">
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-box-arrow-down-right me-3"></i
        >{{ $t("operation_types.from") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: operationDetails.from,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ operationDetails.from }}</router-link
        >
        <span
          v-if="operationDetails.source_account === operationDetails.from"
          class="badge rounded-pill bg-secondary ms-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
          :data-bs-original-title="
            $t('operation_types.sender_account_matches_source')
          "
          v-tooltip
          >{{ $t("operation_types.matching") }}</span
        >
        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails.from)"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-box-arrow-in-up-right me-3"></i
        >{{ $t("operation_types.to") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: operationDetails.to,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ operationDetails.to }}</router-link
        >
        <span
          v-if="operationDetails.source_account === operationDetails.to"
          class="badge rounded-pill bg-secondary ms-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
          :data-bs-original-title="
            $t('operation_types.receiving_account_matches_source')
          "
          v-tooltip
          >{{ $t("operation_types.matching") }}</span
        >
        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails.to)"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-arrow-bar-right me-3"></i
        >{{ $t("operation_types.sent") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace"
          >{{ operationDetails.source_amount }}
          <asset-details-link
            class="text-decoration-none"
            :type="operationDetails.source_asset_type"
            :code="operationDetails.source_asset_code"
            :issuer="operationDetails.source_asset_issuer"
        /></span>
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-arrow-bar-left me-3"></i
        >{{ $t("operation_types.received") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace"
          >{{ operationDetails.amount }}
          <asset-details-link
            class="text-decoration-none"
            :type="operationDetails.asset_type"
            :code="operationDetails.asset_code"
            :issuer="operationDetails.asset_issuer"
        /></span>
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-signpost me-3"></i>{{ $t("operation_types.path") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace">
          <template v-for="(asset, idx) in operationDetails.path" :key="idx">
            <asset-details-link
              class="text-decoration-none"
              :type="asset.asset_type"
              :code="asset.asset_code"
              :issuer="asset.asset_issuer"
            />{{
              idx !== operationDetails.path.length - 1 ? " -> " : null
            }}</template
          >
        </span>
      </div>
    </li>
  </template>
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              v-tooltip
              :data-bs-original-title="`${
                $t('operation_types.operation') + idx
              } <br /> ${$t('operation_types.ops.path_payment_strict_send')}`"
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: operationDetails.id,
                },
              }"
            >
              <i
                class="
                  bi bi-shuffle
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-reset
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.source_account]
                        ? accountLabels.data[operationDetails.source_account]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.source_account')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_account,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.source_account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-body-tertiary rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'OpDetailsRoute',
                      params: {
                        op_id: operationDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-reset
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.operation_type')
                    "
                    v-tooltip
                  >
                    {{ $t("operation_types.ops.path_payment_strict_send") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'OpDetailsRoute',
                        params: {
                          op_id: operationDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.operation_id')
                      "
                      v-tooltip
                    >
                      {{ operationDetails.id }}
                    </router-link>
                  </p>
                </div>
                <span
                  class="
                    badge
                    bg-primary bg-opacity-25
                    text-primary
                    me-2
                    align-self-center
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.paths')"
                  v-tooltip
                  >{{ operationDetails.path.length }}</span
                >
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('operation_types.asset_being_sent')"
                v-tooltip
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :type="operationDetails.source_asset_type"
                  :code="operationDetails.source_asset_code"
                  :issuer="operationDetails.source_asset_issuer"
                >
                  <img
                    v-if="operationDetails.source_asset_type === 'native'"
                    :src="xlmLogo"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="sourceAssetMeta?.image"
                    :src="sourceAssetMeta?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-reset
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="operationDetails.source_asset_type"
                      :code="operationDetails.source_asset_code"
                      :issuer="operationDetails.source_asset_issuer"
                      class="text-decoration-none text-reset"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                      v-tooltip
                    >
                      {{
                        operationDetails.source_asset_type === "native"
                          ? $t("operation_types.xlm")
                          : operationDetails.source_asset_code
                      }}</asset-details-link
                    >
                    <span> {{ operationDetails.source_amount }}</span>
                  </div>
                  <div
                    class="
                      text-reset
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="operationDetails.source_asset_type === 'native'"
                      :type="operationDetails.source_asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("operation_types.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="operationDetails.source_asset_type"
                      :code="operationDetails.source_asset_code"
                      :issuer="operationDetails.source_asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_asset_issuer,
                      }"
                    ></asset-details-link>
                    <span
                      class="small text-muted"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        $t('operation_types.destination_min')
                      "
                      v-tooltip
                      >{{ operationDetails.destination_min }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex bg-body-tertiary rounded"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  $t('operation_types.asset_being_received')
                "
                v-tooltip
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :type="operationDetails.asset_type"
                  :code="operationDetails.asset_code"
                  :issuer="operationDetails.asset_issuer"
                >
                  <img
                    v-if="operationDetails.asset_type === 'native'"
                    :src="xlmLogo"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="assetMeta?.image"
                    :src="assetMeta?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-reset
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="operationDetails.asset_type"
                      :code="operationDetails.asset_code"
                      :issuer="operationDetails.asset_issuer"
                      class="text-decoration-none text-reset"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                      v-tooltip
                      >{{
                        operationDetails.asset_type === "native"
                          ? $t("operation_types.xlm")
                          : operationDetails.asset_code
                      }}</asset-details-link
                    >
                    <span>{{ operationDetails.amount }} </span>
                  </div>
                  <div
                    class="
                      text-reset
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="operationDetails.asset_type === 'native'"
                      :type="operationDetails.asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("operation_types.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="operationDetails.asset_type"
                      :code="operationDetails.asset_code"
                      :issuer="operationDetails.asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.asset_issuer,
                      }"
                    ></asset-details-link>
                    <span
                      class="small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        formatDate(operationDetails.created_at)
                      "
                      v-tooltip
                      >{{
                        formatDateElapsedTime(operationDetails.created_at)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "PathPaymentStrictSend",
  components: {
    AssetDetailsLink,
    AccountLabel,
  },
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
    assetMeta: Object,
    sourceAssetMeta: Object,
    xlmLogo: String,
  },
  setup() {
    const store = useStore();
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    return { formatDateElapsedTime, formatDate, copy, accountLabels };
  },
});
</script>
