<template>
  <template v-if="details">
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-hash me-3"></i
        >{{ $t("operation_types.liquidity_pool_id") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span
          class="align-self-center small font-monospace text-decoration-none"
        >
          {{ operationDetails.liquidity_pool_id }}
        </span>
        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails.liquidity_pool_id)"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-sort-down me-3"></i
        >{{ $t("operation_types.min_price") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span
          class="align-self-center small font-monospace text-decoration-none"
        >
          {{ operationDetails.min_price }}
        </span>
        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails.min_price)"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-sort-up me-3"></i>{{ $t("operation_types.max_price") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span
          class="align-self-center small font-monospace text-decoration-none"
        >
          {{ operationDetails.max_price }}
        </span>
        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails.max_price)"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
    </li>
    <li
      v-for="(reserve, idx) in operationDetails.reserves_deposited"
      :key="idx"
      class="list-group-item d-flex p-0 border-0"
    >
          <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-caret-right-fill me-3"></i
        >{{ $t("operation_types.deposited_reserve") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace"
          >{{ reserve.amount }}
          <asset-details-link
            class="text-decoration-none"
            :sep11="reserve.asset"
        /></span>
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-percent me-3"></i
        >{{ $t("operation_types.shares_received") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace"
          >{{ operationDetails.shares_received }}
        </span>
      </div>
    </li>
    <li
      v-for="(reserve, idx) in operationDetails.reserves_max"
      :key="idx"
      class="list-group-item d-flex p-0 border-0"
    >
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-caret-right me-3"></i
        >{{ $t("operation_types.max_reserve") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace"
          >{{ reserve.amount }}
          <asset-details-link
            class="text-decoration-none"
            :sep11="reserve.asset"
        /></span>
      </div>
    </li>
  </template>
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-body-tertiary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('operation_types.operation') + idx
              } <br /> ${$t('operation_types.ops.liquidity_pool_deposit')}`"
              v-tooltip
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: operationDetails.id,
                },
              }"
            >
              <i
                class="
                  bi bi-cloud-arrow-up-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-body
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.source_account]
                        ? accountLabels.data[operationDetails.source_account]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.source_account')
                      "
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_account,
                      }"
                      v-tooltip
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.source_account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-body-tertiary rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'OpDetailsRoute',
                      params: {
                        op_id: operationDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-body
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.operation_type')
                    "
                    v-tooltip
                  >
                    {{ $t("operation_types.ops.liquidity_pool_deposit") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'OpDetailsRoute',
                        params: {
                          op_id: operationDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.operation_id')
                      "
                      v-tooltip
                    >
                      {{ operationDetails.id }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('operation_types.deposited_asset')"
                v-tooltip
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :sep11="operationDetails.reserves_deposited[0].asset"
                >
                  <img
                    v-if="
                      operationDetails.reserves_deposited[0].asset === 'native'
                    "
                    :src="xlmLogo"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="reservesAssetMeta?.image"
                    :src="reservesAssetMeta?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-body
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :sep11="operationDetails.reserves_deposited[0].asset"
                      class="text-decoration-none text-body"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                      v-tooltip
                    >
                      {{
                        operationDetails.reserves_deposited[0].asset ===
                        "native"
                          ? $t("operation_types.xlm")
                          : operationDetails.reserves_deposited[0].asset.split(
                              ":"
                            )[0]
                      }}</asset-details-link
                    >
                    <span>
                      {{ operationDetails.reserves_deposited[0].amount }}</span
                    >
                  </div>
                  <div
                    class="
                      text-body
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="
                        operationDetails.reserves_deposited[0].asset ===
                        'native'
                      "
                      :type="operationDetails.reserves_deposited[0].asset"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("operation_types.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :sep11="operationDetails.reserves_deposited[0].asset"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.reserves_deposited[0].asset.split(
                          ':'
                        )[1],
                      }"
                    ></asset-details-link>
                    <span
                      class="small text-muted"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        $t('operation_types.shares_received')
                      "
                      v-tooltip
                      >{{ operationDetails.shares_received }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex bg-body-tertiary rounded"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('operation_types.deposited_asset')"
                v-tooltip
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :sep11="operationDetails.reserves_deposited[1].asset"
                  ><img
                    v-if="
                      operationDetails.reserves_deposited[1].asset === 'native'
                    "
                    :src="xlmLogo"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="reservesAssetMeta2?.image"
                    :src="reservesAssetMeta2?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-body
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :sep11="operationDetails.reserves_deposited[1].asset"
                      class="text-decoration-none text-body"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                      v-tooltip
                      >{{
                        operationDetails.reserves_deposited[1].asset ===
                        "native"
                          ? $t("operation_types.xlm")
                          : operationDetails.reserves_deposited[1].asset.split(
                              ":"
                            )[0]
                      }}</asset-details-link
                    >
                    <span
                      >{{ operationDetails.reserves_deposited[1].amount }}
                    </span>
                  </div>
                  <div
                    class="
                      text-body
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="
                        operationDetails.reserves_deposited[1].asset ===
                        'native'
                      "
                      :type="operationDetails.reserves_deposited[1].asset"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("operation_types.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :sep11="operationDetails.reserves_deposited[1].asset"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.reserves_deposited[1].asset.split(
                          ':'
                        )[1],
                      }"
                    ></asset-details-link>
                    <span
                      class="small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        formatDate(operationDetails.created_at)
                      "
                      v-tooltip
                      >{{
                        formatDateElapsedTime(operationDetails.created_at)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import { useStore } from "vuex";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "LiquidityPoolDeposit",
  components: {
    AssetDetailsLink,
    AccountLabel,
  },
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
    reservesAssetMeta: Object,
    reservesAssetMeta2: Object,
    xlmLogo: String,
  },
  setup() {
    const store = useStore();
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    return { formatDateElapsedTime, formatDate, copy, accountLabels };
  },
});
</script>
