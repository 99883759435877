<template>
  <template v-if="details">
    <li
      v-if="operationDetails.trustee"
      class="list-group-item d-flex p-0 border-0"
    >
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-shield me-3"></i>{{ $t("operation_types.trustee") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: operationDetails.trustee,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ operationDetails.trustee }}</router-link
        >

        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails.trustee)"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-shield-exclamation me-3"></i
        >{{ $t("operation_types.trustor") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: operationDetails.trustor,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ operationDetails.trustor }}</router-link
        >
        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails.trustor)"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-currency-bitcoin me-3"></i
        >{{ $t("operation_types.asset") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace"
          ><asset-details-link
            class="text-decoration-none"
            :type="operationDetails.asset_type"
            :code="operationDetails.asset_code"
            :issuer="operationDetails.asset_issuer"
        /></span>
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-currency-bitcoin me-3"></i
        >{{ $t("operation_types.trustline") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace">
          {{
            operationDetails.authorize
              ? $t("operation_types.authorized")
              : operationDetails.authorize_to_maintain_liabilities
              ? $t("operation_types.authorized_to_maintain_liabilities")
              : $t("operation_types.unauthorized")
          }}</span
        >
      </div>
    </li>
  </template>
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('operation_types.operation') + idx
              } <br /> ${$t('operation_types.ops.allow_trust')}`"
              v-tooltip
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: operationDetails.id,
                },
              }"
            >
              <i
                class="
                  bi bi-check-circle-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.source_account]
                        ? accountLabels.data[operationDetails.source_account]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.source_account')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_account,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.source_account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-light rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'OpDetailsRoute',
                      params: {
                        op_id: operationDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.operation_type')
                    "
                    v-tooltip
                  >
                    {{ $t("operation_types.ops.allow_trust") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'OpDetailsRoute',
                        params: {
                          op_id: operationDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.operation_id')
                      "
                      v-tooltip
                    >
                      {{ operationDetails.id }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('operation_types.asset_being_sold')"
                v-tooltip
              >
                <asset-details-link
                  :type="operationDetails.asset_type"
                  :code="operationDetails.asset_code"
                  :issuer="operationDetails.asset_issuer"
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                >
                  <img
                    v-if="operationDetails.asset_type === 'native'"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="xlmLogo"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="assetMeta?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="assetMeta?.image"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="operationDetails.asset_type"
                      :code="operationDetails.asset_code"
                      :issuer="operationDetails.asset_issuer"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                      v-tooltip
                    >
                      {{
                        operationDetails.asset_type === "native"
                          ? $t("operation_types.xlm")
                          : operationDetails.asset_code
                      }}</asset-details-link
                    >
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="operationDetails.asset_type === 'native'"
                      :type="operationDetails.asset_type"
                      :code="operationDetails.asset_code"
                      :issuer="operationDetails.asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.asset_issuer')
                      "
                      v-tooltip
                    >
                      {{ $t("operation_types.native") }}</asset-details-link
                    >
                    <asset-details-link
                      v-else
                      :type="operationDetails.asset_type"
                      :code="operationDetails.asset_code"
                      :issuer="operationDetails.asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.asset_issuer,
                      }"
                    >
                    </asset-details-link>
                  </div>
                </div>
                <div
                  v-if="assetMeta && assetMeta.verified"
                  class="flex-shrink-0 me-2"
                >
                  <i
                    class="bi bi-patch-check-fill text-primary fs-5"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.verified_asset')
                    "
                    v-tooltip
                  ></i>
                </div>
              </div>
              <div
                class="
                  ms-xxl-auto
                  col-xxl-3
                  align-items-center
                  d-flex
                  bg-light
                  rounded
                "
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  $t('operation_types.operation_timestamp')
                "
                v-tooltip
              >
                <div class="flex-shrink-0 mx-2">
                  <i class="bi bi-clock fs-4"></i>
                </div>
                <div
                  class="flex-grow-1 text-dark text-end small font-monospace"
                >
                  <b class="small">{{
                    formatDate(operationDetails.created_at)
                  }}</b>
                  <p class="small mb-0">
                    {{ formatDateElapsedTime(operationDetails.created_at) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "AllowTrust",
  components: {
    AssetDetailsLink,
    AccountLabel,
  },
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
    assetMeta: Object,
    xlmLogo: String,
  },
  setup() {
    const store = useStore();
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    return {
      formatDateElapsedTime,
      formatDate,
      copy,
      accountLabels,
    };
  },
});
</script>
