
<template>
  <template v-if="details">
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-wallet me-3"></i
        >{{ $t("operation_types.function") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span 
          v-tooltip
          class="align-self-center small font-monospace"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="hostFunctions[operationDetails.function]"
        > 
          {{ operationDetails.function }}
        </span>
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-person-plus me-3"></i>
        {{ $t("operation_types.parameters") }}
      </div>
      <div class="w-75 py-2 px-3">
        <div v-if="operationDetails.parameters && operationDetails.parameters.length"
          v-tooltip
          class="align-self-center small font-monospace"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('operation_types.parameters_tooltip')"
        >
          <div v-for="parameter in operationDetails.parameters"  :key="parameter.value" class="small font-monospace">
            {{parameter.type}}:
            {{ toNativeVal(parameter.value) }}
          </div>
        </div>
        <div v-else class="align-self-center small font-monospace">
           No parameters
        </div>
      </div>
    </li>
  </template>
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              v-tooltip
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('operation_types.operation') + idx
              } <br /> ${$t('operation_types.ops.invoke_host_function')}`"
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: operationDetails.id,
                },
              }"
            >
              <i
                class="
                  bi bi-plus-circle-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    v-tooltip
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-body
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                  >
                    {{
                      accountLabels.data[operationDetails.source_account]
                        ? accountLabels.data[operationDetails.source_account]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_account,
                      }"
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.account || operationDetails.address || operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.source_account')
                      "
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.source_account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-body-tertiary rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    v-tooltip
                    :to="{
                      name: 'OpDetailsRoute',
                      params: {
                        op_id: operationDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-body
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.operation_type')
                    "
                  >
                    {{ $t("operation_types.ops.invoke_host_function") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      v-tooltip
                      :to="{
                        name: 'OpDetailsRoute',
                        params: {
                          op_id: operationDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.operation_id')
                      "
                    >
                      {{ operationDetails.id }}
                    </router-link>
                  </p>
                </div>
                <span
                  class="
                    badge
                    bg-primary bg-opacity-25
                    text-primary
                    me-2
                    align-self-center
                  "
                  >{{ $t("operation_types.new") }}</span
                >
              </div>
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    v-tooltip
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.account || operationDetails.address || operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-body
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                  >
                    {{
                      accountLabels.data[operationDetails.account]
                        ? accountLabels.data[operationDetails.account].label
                            .name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.account || operationDetails.address || operationDetails.source_account,
                      }"
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.account || operationDetails.address || operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.destination')
                      "
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <i
                    v-if="
                      accountLabels.data[operationDetails.source_account] &&
                      !accountLabels.data[operationDetails.source_account].label
                        .verified
                    "
                    v-tooltip
                    class="bi bi-person-circle fs-5"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.unverified_user_defined_label')
                    "
                  ></i>
                  <i
                    v-else-if="
                      accountLabels.data[operationDetails.source_account] &&
                      accountLabels.data[operationDetails.source_account].label
                        .verified
                    "
                    v-tooltip
                    class="bi bi-patch-check-fill text-primary fs-5"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.verified_account')
                    "
                  ></i>
                  <router-link
                    v-else
                    :to="{
                      name: 'DirectoryUpdateRoute',
                      query: {
                        account: operationDetails.source_account,
                      },
                    }"
                    ><i
                      v-tooltip
                      class="bi bi-plus-circle fs-5 text-success"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="$t('operation_types.add_label')"
                    ></i
                  ></router-link>
                </div>
              </div>
              <div class="col-xxl-3 align-items-center d-flex bg-body-tertiary rounded">
                <asset-details-link
                  v-tooltip
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.asset_icon')"
                  type="native"
                >
                  <img
                    src="@/assets/images/assets/xlm.png"
                    class="px-1 align-self-center mx-auto img-fluid"
                    alt="icon"
                  />
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-body
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-tooltip
                      type="native"
                      class="text-decoration-none text-body"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                    >
                      {{ $t("operation_types.xlm") }}</asset-details-link
                    >
                    <span> {{ operationDetails.starting_balance }}</span>
                  </div>
                  <div
                    class="
                      text-body
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <span
                      v-tooltip
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.native_asset_of_stellar_network')
                      "
                      >{{ $t("operation_types.native") }}</span
                    >
                    <span
                      v-tooltip
                      class="small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        formatDate(operationDetails.created_at)
                      "
                    >
                      {{ formatDateElapsedTime(operationDetails.created_at) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AccountLabel from "@/components/AccountLabel.vue";
import {ContractSpec, xdr, scValToNative} from 'stellar-sdk'


export default defineComponent({
  name: "InvokeHostFunction",
  components: { AssetDetailsLink, AccountLabel },
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
  },
  setup() {
    const store = useStore();
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };

    BigInt.prototype.toJSON = function () {return this.toString()}
    const toNativeVal = (value) => {
      try {
        let xdrValue = xdr.ScVal.fromXDR(value, "base64")
        let parsedValue = scValToNative(xdrValue);
        return parsedValue;
      } catch (e){
        console.log(e)
      }
    }
          const hostFunctions = {
      'HostFunctionTypeHostFunctionTypeInvokeContract': 'Invoke contract function from the contract code.',
      'HostFunctionTypeHostFunctionTypeCreateContract': 'Create contract from the uploaded contract code.',
      'HostFunctionTypeHostFunctionTypeUploadContractWasm': 'Uploading binary version of the contract code to the ledger.'
    }

    return { toNativeVal, formatDateElapsedTime, formatDate, copy, accountLabels, xdr, hostFunctions};
  },
});
</script>
