
<script>
import { defineComponent, h, computed } from "vue";
import { useStore } from "vuex";
import CreateAccount from "./types/CreateAccount.vue";
import Payment from "./types/Payment.vue";
import PathPaymentStrictReceive from "./types/PathPaymentStrictReceive.vue";
import ManageSellOffer from "./types/ManageSellOffer.vue";
import CreatePassiveSellOffer from "./types/CreatePassiveSellOffer.vue";
import SetOptions from "./types/SetOptions.vue";
import ChangeTrust from "./types/ChangeTrust.vue";
import AllowTrust from "./types/AllowTrust.vue";
import AccountMerge from "./types/AccountMerge.vue";
import Inflation from "./types/Inflation.vue";
import ManageData from "./types/ManageData.vue";
import BumpSequence from "./types/BumpSequence.vue";
import ManageBuyOffer from "./types/ManageBuyOffer.vue";
import PathPaymentStrictSend from "./types/PathPaymentStrictSend.vue";
import CreateClaimableBalance from "./types/CreateClaimableBalance.vue";
import ClaimClaimableBalance from "./types/ClaimClaimableBalance.vue";
import BeginSponsoringFutureReserves from "./types/BeginSponsoringFutureReserves.vue";
import EndSponsoringFutureReserves from "./types/EndSponsoringFutureReserves.vue";
import RevokeSponsorship from "./types/RevokeSponsorship.vue";
import Clawback from "./types/Clawback.vue";
import ClawbackClaimableBalance from "./types/ClawbackClaimableBalance.vue";
import SetTrustLineFlags from "./types/SetTrustLineFlags.vue";
import LiquidityPoolWithdraw from "./types/LiquidityPoolWithdraw.vue";
import LiquidityPoolDeposit from "./types/LiquidityPoolDeposit.vue";
import InvokeHostFunction from "./types/InvokeHostFunction.vue";
import RestoreFootPrint from "./types/RestoreFootPrint.vue";


export default defineComponent({
  name: "OperationInfo",
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
  },
  components() {},
  setup(props) {
    const store = useStore();
    const assetsMeta = computed(
      () => store.getters["assets/getAssetsMeta"].data
    );
    const xlmLogo = require("@/assets/images/assets/xlm.png");
    return () => {
      switch (props.operationDetails.type) {
        case "create_account":
          return h(CreateAccount, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "payment":
          return h(Payment, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "path_payment_strict_receive":
          return h(PathPaymentStrictReceive, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            sourceAssetMeta:
              assetsMeta.value[
                props.operationDetails.source_asset_code +
                  "_" +
                  props.operationDetails.source_asset_issuer
              ],
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "manage_sell_offer":
          return h(ManageSellOffer, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            sellingAssetMeta:
              assetsMeta.value[
                props.operationDetails.selling_asset_code +
                  "_" +
                  props.operationDetails.selling_asset_issuer
              ],
            buyingAssetMeta:
              assetsMeta.value[
                props.operationDetails.buying_asset_code +
                  "_" +
                  props.operationDetails.buying_asset_issuer
              ],
            details: props.details,
          });
        case "create_passive_sell_offer":
          return h(CreatePassiveSellOffer, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            sellingAssetMeta:
              assetsMeta.value[
                props.operationDetails.selling_asset_code +
                  "_" +
                  props.operationDetails.selling_asset_issuer
              ],
            buyingAssetMeta:
              assetsMeta.value[
                props.operationDetails.buying_asset_code +
                  "_" +
                  props.operationDetails.buying_asset_issuer
              ],
            details: props.details,
          });
        case "set_options":
          return h(SetOptions, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "change_trust":
          return h(ChangeTrust, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "allow_trust":
          return h(AllowTrust, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "account_merge":
          return h(AccountMerge, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "inflation":
          return h(Inflation, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "manage_data":
          return h(ManageData, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "bump_sequence":
          return h(BumpSequence, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "manage_buy_offer":
          return h(ManageBuyOffer, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            sellingAssetMeta:
              assetsMeta.value[
                props.operationDetails.selling_asset_code +
                  "_" +
                  props.operationDetails.selling_asset_issuer
              ],
            buyingAssetMeta:
              assetsMeta.value[
                props.operationDetails.buying_asset_code +
                  "_" +
                  props.operationDetails.buying_asset_issuer
              ],
            details: props.details,
          });
        case "path_payment_strict_send":
          return h(PathPaymentStrictSend, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            sourceAssetMeta:
              assetsMeta.value[
                props.operationDetails.source_asset_code +
                  "_" +
                  props.operationDetails.source_asset_issuer
              ],
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "create_claimable_balance": {
          const assetArr = props.operationDetails.asset.split(":");
          return h(CreateClaimableBalance, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta: assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            details: props.details,
          });
        }
        case "claim_claimable_balance":
          return h(ClaimClaimableBalance, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "begin_sponsoring_future_reserves":
          return h(BeginSponsoringFutureReserves, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "end_sponsoring_future_reserves":
          return h(EndSponsoringFutureReserves, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "revoke_sponsorship":
          return h(RevokeSponsorship, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "clawback":
          return h(Clawback, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "clawback_claimable_balance":
          return h(ClawbackClaimableBalance, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "set_trust_line_flags":
          return h(SetTrustLineFlags, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "liquidity_pool_deposit": {
          const assetArr =
            props.operationDetails.reserves_deposited[0].asset.split(":");
          const assetArr2 =
            props.operationDetails.reserves_deposited[1].asset.split(":");
          return h(LiquidityPoolDeposit, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            reservesAssetMeta:
              assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            reservesAssetMeta2:
              assetsMeta.value[assetArr2[0] + "_" + assetArr2[1]],
            details: props.details,
          });
        }
        case "liquidity_pool_withdraw": {
          const assetArr =
            props.operationDetails.reserves_received[0].asset.split(":");
          const assetArr2 =
            props.operationDetails.reserves_received[1].asset.split(":");
          return h(LiquidityPoolWithdraw, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            reservesAssetMeta:
              assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            reservesAssetMeta2:
              assetsMeta.value[assetArr2[0] + "_" + assetArr2[1]],
            details: props.details,
          });
        }
        case 'invoke_host_function':
          return h(InvokeHostFunction, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
            
        case 'restore_footprint':
          return h(RestoreFootPrint, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        default:
          return [];
      }
    };
  },
});
</script>
