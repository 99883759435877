<template>
  <template v-if="details">
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-person me-3"></i>{{ $t("operation_types.sponsor") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: operationDetails.begin_sponsor,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ operationDetails.begin_sponsor }}</router-link
        >
        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails.begin_sponsor)"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
    </li>
  </template>
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('operation_types.operation') + idx
              } <br /> ${$t(
                'operation_types.ops.end_sponsoring_future_reserves'
              )}`"
              v-tooltip
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: operationDetails.id,
                },
              }"
            >
              <i
                class="
                  bi bi-bookmark-x-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.source_account]
                        ? accountLabels.data[operationDetails.source_account]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.source_account')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_account,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.source_account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-light rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'OpDetailsRoute',
                      params: {
                        op_id: operationDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.operation_type')
                    "
                    v-tooltip
                  >
                    {{
                      $t("operation_types.ops.end_sponsoring_future_reserves")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'OpDetailsRoute',
                        params: {
                          op_id: operationDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.operation_id')
                      "
                      v-tooltip
                    >
                      {{ operationDetails.id }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.begin_sponsor,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.begin_sponsor]
                        ? accountLabels.data[operationDetails.begin_sponsor]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.begin_sponsor,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.sponsor')"
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.begin_sponsor,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.begin_sponsor" />
                </div>
              </div>
              <div
                class="
                  ms-xxl-auto
                  col-xxl-3
                  align-items-center
                  d-flex
                  bg-light
                  rounded
                "
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                v-tooltip
                :data-bs-original-title="
                  $t('operation_types.operation_timestamp')
                "
              >
                <div class="flex-shrink-0 mx-2">
                  <i class="bi bi-clock fs-4"></i>
                </div>
                <div
                  class="flex-grow-1 text-dark text-end small font-monospace"
                >
                  <b class="small">{{
                    formatDate(operationDetails.created_at)
                  }}</b>
                  <p class="small mb-0">
                    {{ formatDateElapsedTime(operationDetails.created_at) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "EndSponsoringFutureReserves",
  components: { AccountLabel },
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
  },
  setup() {
    const store = useStore();
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    return { formatDateElapsedTime, formatDate, copy, accountLabels };
  },
});
</script>
