<template>
  <template v-if="details">
    <li
      class="list-group-item d-flex p-0 border-0"
      v-for="(option, idx) in opts"
      :key="idx"
    >
      <div class="w-25 py-2 px-3 bg-body-tertiary">
        <i class="bi bi-caret-right-fill me-3"></i
        >{{ $t(`operation_types.${option}`) }}
      </div>
      <div
        v-if="['signer_key', 'inflation_dest'].includes(option)"
        class="w-75 py-2 px-3"
      >
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: operationDetails[`${option}`],
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ operationDetails[`${option}`] }}</router-link
        >
        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(operationDetails[`${option}`])"
          >{{ $t("operation_types.copy") }}</router-link
        >
      </div>
      <div v-else class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace">{{
          ["set_flags_s", "clear_flags_s"].includes(option)
            ? operationDetails[`${option}`].join(", ")
            : operationDetails[`${option}`]
        }}</span>
      </div>
    </li>
  </template>
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('operation_types.operation') + idx
              } <br /> ${$t('operation_types.ops.set_options')}`"
              v-tooltip
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: operationDetails.id,
                },
              }"
            >
              <i
                class="
                  bi bi-info-circle-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-body
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.source_account]
                        ? accountLabels.data[operationDetails.source_account]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.source_account')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_account,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.source_account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-body-tertiary rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'OpDetailsRoute',
                      params: {
                        op_id: operationDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-body
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.operation_type')
                    "
                    v-tooltip
                  >
                    {{ $t("operation_types.ops.set_options") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'OpDetailsRoute',
                        params: {
                          op_id: operationDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.operation_id')
                      "
                      v-tooltip
                    >
                      {{ operationDetails.id }}
                    </router-link>
                  </p>
                </div>
                <span
                  class="
                    badge
                    bg-primary bg-opacity-25
                    text-primary
                    me-2
                    align-self-center
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.no_options')"
                  v-tooltip
                  >{{ opts.length }}</span
                >
              </div>

              <div
                class="
                  ms-xxl-auto
                  col-xxl-3
                  align-items-center
                  d-flex
                  bg-body-tertiary
                  rounded
                "
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  $t('operation_types.operation_timestamp')
                "
                v-tooltip
              >
                <div class="flex-shrink-0 mx-2">
                  <i class="bi bi-clock fs-4"></i>
                </div>
                <div
                  class="flex-grow-1 text-body text-end small font-monospace"
                >
                  <b class="small">{{
                    formatDate(operationDetails.created_at)
                  }}</b>
                  <p class="small mb-0">
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        formatDate(operationDetails.created_at)
                      "
                      v-tooltip
                      >{{ formatDateElapsedTime(operationDetails.created_at) }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import { useStore } from "vuex";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "SetOptions",
  components: { AccountLabel },
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
  },
  setup(props) {
    const store = useStore();
    const options = [
      "signer_key",
      "signer_weight",
      "master_key_weight",
      "low_threshold",
      "med_threshold",
      "high_threshold",
      "home_domain",
      "inflation_dest",
      "set_flags_s",
      "clear_flags_s",
    ];

    const opts = computed(() =>
      Object.keys(props.operationDetails).filter((key) => options.includes(key))
    );
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    return { formatDateElapsedTime, formatDate, opts, copy, accountLabels };
  },
});
</script>
