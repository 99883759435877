
<template>
  <template v-if="details">
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-wallet me-3"></i
        >{{ $t("operation_types.function") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="small font-monospae"> {{ operationDetails.function }} </span>
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-person-plus me-3"></i>
        {{ $t("operation_types.parameters") }}
      </div>
      <div class="w-75 py-2 px-3">
        <div v-for="parameter in operationDetails.parameters" :key="parameter.value" class="small font-monospace">
            {{parameter.type}}: 
            <span v-if ="parameter.type == 'Address'"> {{ xdr.ScVal.fromXDR(parameter.value, "base64")?.value()._value?.toString('hex')}}</span> 
            <span v-if ="parameter.type == 'Bytes'"> {{ xdr.ScVal.fromXDR(parameter.value, "base64")?.value().toString('hex')}}</span> 
            <span v-if ="parameter.type == 'Sym'"> {{ xdr.ScVal.fromXDR(parameter.value, "base64").value().toString() }}</span> 
        </div>
      </div>
    </li>
  </template>
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('operation_types.operation') + idx
              } <br /> ${$t('operation_types.ops.invoke_host_function')}`"
              v-tooltip
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: operationDetails.id,
                },
              }"
            >
              <i
                class="
                  bi bi-plus-circle-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.source_account]
                        ? accountLabels.data[operationDetails.source_account]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.account || operationDetails.address || operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.source_account')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_account,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.source_account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-light rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'OpDetailsRoute',
                      params: {
                        op_id: operationDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.operation_type')
                    "
                    v-tooltip
                  >
                    {{ $t("operation_types.ops.invoke_host_function") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'OpDetailsRoute',
                        params: {
                          op_id: operationDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.operation_id')
                      "
                      v-tooltip
                    >
                      {{ operationDetails.id }}
                    </router-link>
                  </p>
                </div>
                <span
                  class="
                    badge
                    bg-primary bg-opacity-25
                    text-primary
                    me-2
                    align-self-center
                  "
                  >{{ $t("operation_types.new") }}</span
                >
              </div>
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.account || operationDetails.address || operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.account]
                        ? accountLabels.data[operationDetails.account].label
                            .name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.account || operationDetails.address || operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.destination')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.account || operationDetails.address || operationDetails.source_account,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <i
                    v-if="
                      accountLabels.data[operationDetails.source_account] &&
                      !accountLabels.data[operationDetails.source_account].label
                        .verified
                    "
                    class="bi bi-person-circle fs-5"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.unverified_user_defined_label')
                    "
                    v-tooltip
                  ></i>
                  <i
                    v-else-if="
                      accountLabels.data[operationDetails.source_account] &&
                      accountLabels.data[operationDetails.source_account].label
                        .verified
                    "
                    class="bi bi-patch-check-fill text-primary fs-5"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.verified_account')
                    "
                    v-tooltip
                  ></i>
                  <router-link
                    v-else
                    :to="{
                      name: 'DirectoryUpdateRoute',
                      query: {
                        account: operationDetails.source_account,
                      },
                    }"
                    ><i
                      class="bi bi-plus-circle fs-5 text-success"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="$t('operation_types.add_label')"
                      v-tooltip
                    ></i
                  ></router-link>
                </div>
              </div>
              <div class="col-xxl-3 align-items-center d-flex bg-light rounded">
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.asset_icon')"
                  v-tooltip
                  type="native"
                >
                  <img
                    src="@/assets/images/assets/xlm.png"
                    class="px-1 align-self-center mx-auto img-fluid"
                    alt="icon"
                  />
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      type="native"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                      v-tooltip
                    >
                      {{ $t("operation_types.xlm") }}</asset-details-link
                    >
                    <span> {{ operationDetails.starting_balance }}</span>
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.native_asset_of_stellar_network')
                      "
                      v-tooltip
                      >{{ $t("operation_types.native") }}</span
                    >
                    <span
                      class="small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        formatDate(operationDetails.created_at)
                      "
                      v-tooltip
                    >
                      {{ formatDateElapsedTime(operationDetails.created_at) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AccountLabel from "@/components/AccountLabel.vue";
import {xdr} from 'stellar-sdk'
export default defineComponent({
  name: "RestoreFootPrint",
  components: { AssetDetailsLink, AccountLabel },
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
  },
  setup() {
    const store = useStore();
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    return { formatDateElapsedTime, formatDate, copy, accountLabels, xdr };
  },
});
</script>
