<template>
  <template v-if="details">
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-arrow-bar-right me-3"></i
        >{{ $t("operation_types.selling") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace"
          >{{ operationDetails.amount }}
          <asset-details-link
            class="text-decoration-none"
            :type="operationDetails.selling_asset_type"
            :code="operationDetails.selling_asset_code"
            :issuer="operationDetails.selling_asset_issuer"
        /></span>
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-arrow-bar-left me-3"></i
        >{{ $t("operation_types.buying") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace"
          >{{ (operationDetails.amount * operationDetails.price).toFixed(7) }}
          <asset-details-link
            class="text-decoration-none"
            :type="operationDetails.buying_asset_type"
            :code="operationDetails.buying_asset_code"
            :issuer="operationDetails.buying_asset_issuer"
        /></span>
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-cash-coin me-3"></i>{{ $t("operation_types.price") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span class="align-self-center small font-monospace">{{
          operationDetails.price
        }}</span>
      </div>
    </li>
  </template>
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('operation_types.operation') + idx
              } <br /> ${$t('operation_types.ops.create_passive_sell_offer')}`"
              v-tooltip
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: operationDetails.id,
                },
              }"
            >
              <i
                class="
                  bi bi-arrow-up-square-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: operationDetails.source_account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[operationDetails.source_account]
                        ? accountLabels.data[operationDetails.source_account]
                            .label.name
                        : $t("operation_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: operationDetails.source_account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.source_account')
                      "
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.source_account,
                      }"
                      v-tooltip
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="operationDetails.source_account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-light rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'OpDetailsRoute',
                      params: {
                        op_id: operationDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.operation_type')
                    "
                    v-tooltip
                  >
                    {{ $t("operation_types.ops.create_passive_sell_offer") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'OpDetailsRoute',
                        params: {
                          op_id: operationDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.operation_id')
                      "
                      v-tooltip
                    >
                      {{ operationDetails.id }}
                    </router-link>
                  </p>
                </div>
                <span
                  class="
                    badge
                    bg-danger bg-opacity-25
                    text-danger
                    me-2
                    align-self-center
                  "
                >
                  {{ $t("operation_types.sell") }}</span
                >
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('operation_types.asset_being_sold')"
                v-tooltip
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :type="operationDetails.selling_asset_type"
                  :code="operationDetails.selling_asset_code"
                  :issuer="operationDetails.selling_asset_issuer"
                >
                  <img
                    v-if="operationDetails.selling_asset_type === 'native'"
                    :src="xlmLogo"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="sellingAssetMeta?.image"
                    :src="sellingAssetMeta?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="operationDetails.selling_asset_type"
                      :code="operationDetails.selling_asset_code"
                      :issuer="operationDetails.selling_asset_issuer"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                      v-tooltip
                    >
                      {{
                        operationDetails.selling_asset_type === "native"
                          ? $t("operation_types.xlm")
                          : operationDetails.selling_asset_code
                      }}
                    </asset-details-link>
                    <span>{{ operationDetails.amount }}</span>
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="operationDetails.selling_asset_type === 'native'"
                      :type="operationDetails.selling_asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("operation_types.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="operationDetails.selling_asset_type"
                      :code="operationDetails.selling_asset_code"
                      :issuer="operationDetails.selling_asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.selling_asset_issuer,
                      }"
                    ></asset-details-link>
                    <div
                      v-if="'offer_id' in operationDetails"
                      class="small text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="$t('operation_types.offer_id')"
                      v-tooltip
                    >
                      {{ operationDetails.offer_id }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex bg-light rounded"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  $t('operation_types.asset_being_bought')
                "
                v-tooltip
              >
                <asset-details-link
                  :type="operationDetails.buying_asset_type"
                  :code="operationDetails.buying_asset_code"
                  :issuer="operationDetails.buying_asset_issuer"
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                >
                  <img
                    v-if="operationDetails.buying_asset_type === 'native'"
                    :src="xlmLogo"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="buyingAssetMeta?.image"
                    :src="buyingAssetMeta?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="operationDetails.buying_asset_type"
                      :code="operationDetails.buying_asset_code"
                      :issuer="operationDetails.buying_asset_issuer"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('operation_types.asset_name')"
                      v-tooltip
                      >{{
                        operationDetails.buying_asset_type === "native"
                          ? $t("operation_types.xlm")
                          : operationDetails.buying_asset_code
                      }}</asset-details-link
                    >
                    <span>{{
                      (
                        operationDetails.amount * operationDetails.price
                      ).toFixed(7)
                    }}</span>
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="operationDetails.buying_asset_type === 'native'"
                      :type="operationDetails.buying_asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("operation_types.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="operationDetails.buying_asset_type"
                      :code="operationDetails.buying_asset_code"
                      :issuer="operationDetails.buying_asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('operation_types.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: operationDetails.buying_asset_issuer,
                      }"
                    ></asset-details-link>

                    <span
                      class="small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-tooltip
                      title=""
                      :data-bs-original-title="
                        formatDate(operationDetails.created_at)
                      "
                      >{{
                        formatDateElapsedTime(operationDetails.created_at)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "CreatePassiveSellOffer",
  components: {
    AssetDetailsLink,
    AccountLabel,
  },
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
    sellingAssetMeta: Object,
    buyingAssetMeta: Object,
    xlmLogo: String,
  },
  setup() {
    const store = useStore();
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    return { formatDateElapsedTime, formatDate, accountLabels };
  },
});
</script>
